import { graphql, useStaticQuery } from "gatsby"
import React, { useMemo } from "react"
import Seo from "../../components/seo"
import Layout from "../../components/Layout/Layout"
import { groupBy } from "lodash"
import FaqItem from "../../components/FaqItem/FaqItem"

import * as styles from "./FAQ.module.scss"

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq {
        edges {
          node {
            question
            answer {
              answer
            }
            category
          }
        }
      }
    }
  `)

  const groupedFaq = useMemo(() => {
    return groupBy(data.allContentfulFaq.edges, edge => edge.node.category)
  }, [data])

  return (
    <>
      <Seo title="Faq" />
      <div className={styles.faq}>
        <h1>FAQ</h1>

        {Object.keys(groupedFaq).map(key => (
          <div className={styles.faqInner}>
            <h2>{key}</h2>
            {groupedFaq[key].map(faq => (
              <FaqItem
                question={faq.node.question}
                answer={faq.node.answer.answer}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  )
}

export default FAQ
